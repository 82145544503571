import { ActionTree } from 'vuex';
import { State } from '@/types';
// eslint-disable-next-line import/no-cycle
import { Api } from '@/http';

const ROOT = { root: true };

const actions: ActionTree<State.Email, State.Root> = {
  async Find({ commit }, { EMAIL_ID }) {
    const res = await Api().get(`emails/${EMAIL_ID}`);

    commit('Push', res.data);

    return res.data;
  },
  async ListEmails({ commit }, { query } = {}) {
    const res = await Api().get('emails', {
      params: query,
    });

    commit('PushList', res.data);

    return res.data;
  },

  async CreateEmail({ commit }, { form }) {
    const res = await Api().post('emails', form);

    commit('Push', res.data);

    return res.data;
  },

  async ChangeEmail({ commit, dispatch }, { EMAIL_ID, TOKEN }) {
    const res = await Api(TOKEN).patch(`emails/${EMAIL_ID}`);

    commit('Push', res.data);

    await dispatch('account/GetAccount', null, ROOT);

    return res.data;
  },

  async DeleteEmail({ commit }, { EMAIL_ID }) {
    const res = await Api().delete(`emails/${EMAIL_ID}`);

    commit('Remove', EMAIL_ID);

    return res.data;
  },
};

export default actions;
