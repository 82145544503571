import { GetterTree } from 'vuex';
import { State } from '@/types';
import { blobFromString } from '@/util/blob';

const getters: GetterTree<State.Mfa, State.Root> = {
  PublicKeyCredentials: (s) => s.publicKeyCredential.list,
  TotpStatus: (state) => state.totp.status,
  TotpRecoverySecrets: (state) => state.totp.recoverySecrets,
  TotpRemainingCodes: (state) => (state.totp.status ? state.totp.status.remaining_codes : null),
  TotpStatusLoaded: (state) => !!state.totp.status,
  TotpSecret: (state) => (state.totp.secret ? state.totp.secret.secret : null),
  TotpSecretLoaded: (state) => !!state.totp.secret,
  TotpSecretBlobUrl: (state) => {
    if (!state.totp.secret) return null;

    return URL.createObjectURL(
      blobFromString(state.totp.secret.qr, state.totp.secret.qr_mime_type),
    );
  },
  TotpSecretQr: (state) => (state.totp.secret ? state.totp.secret.qr : null),
  TotpSecretSrc: (state) => (state.totp.secret ? `data:image/png;base64,${state.totp.secret.qr}` : null),
  TotpSecretUri: (state) => (state.totp.secret ? state.totp.secret.provisioning_uri : null),
};

export default getters;
