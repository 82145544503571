import { GetterTree } from 'vuex';
import { State } from '@/types';

const getters: GetterTree<State.Email, State.Root> = {
  List(state) {
    return state.list;
  },
};

export default getters;
