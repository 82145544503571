import { ActionTree } from 'vuex';
import { State } from '@/types';
// eslint-disable-next-line import/no-cycle
import { Api } from '@/http';

const actions: ActionTree<State.Mfa, State.Root> = {
  async ListPublicKeyCredentials({ commit }) {
    const res = await Api().get('public_key_credentials');

    commit('PushPublicKeyCredentialList', res.data);

    return res.data;
  },

  async GenerateCreationOptions(c, { TOKEN, form }) {
    const res = await Api(TOKEN).post('public_key_credentials', form);

    return res.data;
  },

  async VerifyCreationResponse({ dispatch }, { form, query }) {
    const res = await Api().put('public_key_credentials', form, {
      params: query,
    });

    await dispatch('ListPublicKeyCredentials');

    return res.data;
  },

  async DeletePublicKeyCredential({ commit }, { TOKEN, PUBLIC_KEY_CREDENTIAL_ID }) {
    const res = await Api(TOKEN).delete(`public_key_credentials/${PUBLIC_KEY_CREDENTIAL_ID}`);

    commit('RemovePublicKeyCredential', PUBLIC_KEY_CREDENTIAL_ID);

    return res.data;
  },

  async GetTotpStatus({ commit }) {
    const res = await Api().get('totp');

    commit('SetTotpStatus', res.data.data);

    return res.data;
  },

  async PrepareTotp({ commit }) {
    const res = await Api().post('totp');

    commit('SetTotpSecret', res.data.data);

    return res.data;
  },

  async EnableTotp({ commit }, { TOKEN, form }) {
    const res = await Api(TOKEN).put('totp', form);

    commit('SetTotpStatus', res.data.data);
    commit('SetTotpSecret', null);

    return res.data;
  },

  async DisableTotp({ commit }, { TOKEN }) {
    const res = await Api(TOKEN).delete('totp');

    commit('SetTotpStatus', res.data.data);

    return res.data;
  },

  async GenerateTotpRecoverySecrets({ commit }, { TOKEN, form }) {
    const res = await Api(TOKEN).post('totp_recovery_secrets', form);

    commit('SetTotpRecoverySecrets', res.data.data);

    return res.data;
  },
};

export default actions;
