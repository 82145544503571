export function blobFromString(
  chars: string,
  type?: string,
  endings?: EndingType,
): Blob {
  const bytes = new Uint8Array(chars.length);

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < chars.length; i++) {
    bytes[i] = chars.charCodeAt(i);
  }

  return new Blob([bytes], { type, endings });
}

export function blobFromBase64(
  base64: string,
  type?: string,
  endings?: EndingType,
): Blob {
  return blobFromString(
    atob(base64),
    type,
    endings,
  );
}

export function blobFromArrayBuffer(
  buffer: ArrayBuffer,
  type?: string,
  endings?: EndingType,
): Blob {
  return new Blob([buffer], { type, endings });
}
