/* eslint-disable no-param-reassign */
import { MutationTree } from 'vuex';
import { ApiResource, State } from '@/types';
import { pushResource, removeResourceById } from '@/util/list';

const mutations: MutationTree<State.Mfa> = {
  PushPublicKeyCredential(state, { data }: { data: ApiResource.PublicKeyCredential }) {
    pushResource(state.publicKeyCredential.list, data);
  },
  PushPublicKeyCredentialList(state, { data }: { data: ApiResource.PublicKeyCredential[] }) {
    data.forEach((pkc) => pushResource(state.publicKeyCredential.list, pkc));
  },
  RemovePublicKeyCredential(state, id) {
    removeResourceById(state.publicKeyCredential.list, id);
  },
  SetTotpStatus(state, payload: ApiResource.TotpStatus) {
    state.totp.status = payload;
  },
  SetTotpSecret(state, payload: ApiResource.TotpSecret) {
    state.totp.secret = payload;
  },
  SetTotpRecoverySecrets(state, payload: ApiResource.TotpRecoverySecret[] = []) {
    state.totp.recoverySecrets = payload;
  },
};

export default mutations;
