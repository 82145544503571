/* eslint-disable no-param-reassign */
import { MutationTree } from 'vuex';
import { ApiResource, State } from '@/types';
import { pushResource, removeResourceById } from '@/util/list';

const mutations: MutationTree<State.Email> = {
  Push(state, { data }: { data: ApiResource.Email }) {
    pushResource(state.list, data);
  },
  PushList(state, { data }: { data: ApiResource.Email[], meta: any }) {
    data.forEach((res) => pushResource(state.list, res));
  },
  Remove(state, payload: string) {
    removeResourceById(state.list, payload);
  },
};

export default mutations;
