import { Module } from 'vuex';
import { State } from '@/types';
import getters from './getters';
// eslint-disable-next-line import/no-cycle
import actions from './actions';
import mutations from './mutations';

function defaultState(): State.Mfa {
  return {
    publicKeyCredential: {
      list: [],
    },
    totp: {
      status: null,
      secret: null,
      recoverySecrets: [],
    },
  };
}

const module: Module<State.Mfa, State.Root> = {
  namespaced: true,
  state: defaultState(),
  getters,
  actions,
  mutations,
};

export default module;
